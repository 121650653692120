import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout/Layout";
import useSearch from "../hooks/searchspring/useSearch";
import ProductFilters from "../components/ProductFilters";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import ProductSortFilter from "../components/searchSpring/ProductSortFilter";
import ProductListing from "../components/products/ProductListing";
import Pagination from "../components/common/Pagination";
import { SearchContext } from "../context/SearchContext";
import { staticPage } from "../../config/staticPage";
import { navigate } from "gatsby";

function SearchPage({ location }) {
  const pageWrapperRef = useRef(null);
  const [view, setProductView] = useState("grid");

  const {
    filters,
    products,
    sortingOptions,
    isLoading,
    pagination,
    facets,
    filterSummary,
    redirectURL,
    handlePageChange,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  } = useSearch(null, null, pageWrapperRef);

  // const { breadcrumbTitle } = breadcrumbs;
  const { currentPage, totalPages, totalResults, begin, end } = pagination;

  const contextValues = {
    filters,
    isLoading,
    products,
    facets,
    view,
    filterSummary,
    sortingOptions,
    setProductView,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
    begin,
    end,
    totalResults,
  };

  const CategoyToggle = () => {
    const bodyClass = document.body.classList;

    if (bodyClass.contains("category-toggle-active")) {
      bodyClass.remove("category-toggle-active");
    } else {
      document.body.classList.add("category-toggle-active");
    }
  };

  useEffect(() => {
    if (redirectURL) {
      navigate(redirectURL);
    }
  }, [redirectURL]);

  return (
    <>
      <SearchContext.Provider value={contextValues}>
        <div className="page-wrapper" ref={pageWrapperRef}>
          <div className="container">
            <Breadcrumbs location={location} />
          </div>
          <div className="container">
            <div className="row flex flex-wrap page-layout">
              {/* <div className="mobile-title-col show-mobile">
              <h3 className="page-title page-title-mobile">Search title</h3>
            </div> */}

              <div
                className="mobile-sidebar-toggle show-mobile"
                onClick={() => CategoyToggle()}
              >
                <span className="show-filter">Show Filter</span>
                <span className="hide-filter">Hide Filter</span>
              </div>

              <div className="col page-sidebar page-sidebar-toggle">
                <div className="page-sidebar-inner-wrap">
                  <ProductFilters />
                </div>
              </div>

              <div className="col page-content">
                <div className="page-action-bar flex justify-space vertical-middle search-page-action-bar">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalPages}
                    onPageChange={(page) => handlePageChange(page)}
                  />

                  <ProductSortFilter />
                </div>

                <ProductListing />

                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalPages}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </SearchContext.Provider>
    </>
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "search") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

SearchPage.Layout = Layout;
export default SearchPage;
